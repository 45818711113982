'use client'
import { createContext, ReactNode, useContext, useState } from 'react'
import ErrorModal from '@jobmojito/components/core/ErrorModal'

// Define a type for the context value
type ErrorContextType = {
  error: React.ReactNode | null
  type: 'error' | 'success' | 'warning' | 'default'
  showError: (message: React.ReactNode, type?: 'error' | 'success' | 'warning'| 'default') => void
  clearError: () => void
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined)

export const ErrorProvider = ({ children }: { children: ReactNode }) => {
  const [error, setError] = useState<React.ReactNode | null>(null)
  const [type, setType] = useState<'error' | 'success' | 'warning' | 'default'>('error')

  const showError = (message: React.ReactNode, type?:'error' | 'success' | 'warning' | 'default') => {
    setError(message)
    if (type===undefined) { setType('error') } else { setType(type) }
  }

  const clearError = () => {
    setError(null)
  }

  return (
    <ErrorContext.Provider value={{ error, type, showError, clearError }}>
      <ErrorModal />
      {children}
    </ErrorContext.Provider>
  )
}

export const useError = (): ErrorContextType => {
  const context = useContext(ErrorContext)
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider')
  }
  return context
}
