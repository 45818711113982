import Image from 'next/image'
import tr from './flags-circle/tr.svg'
import sk from './flags-circle/sk.svg'
import en from './flags-circle/en.svg'
import es from './flags-circle/es.svg'
import cs from './flags-circle/cs.svg'
import ru from './flags-circle/ru.svg'
import uk from './flags-circle/uk.svg'
import th from './flags-circle/th.svg'
import hi from './flags-circle/hi.svg'
import fr from './flags-circle/fr.svg'
import de from './flags-circle/de.svg'
import pt from './flags-circle/pt.svg'
import ja from './flags-circle/ja.svg'
import it from './flags-circle/it.svg'
import pl from './flags-circle/pl.svg'
import sv from './flags-circle/sv.svg'
import nl from './flags-circle/nl.svg'
import ar from './flags-circle/ar.svg'

import {
  languagesSorted,
  findLanguage,
} from '@jobmojito/app/utils/data/platform-languages'

export default function findLanguageIcon({code, className}: {code: string | null | undefined, className?:string}): React.ReactNode {
  if ((code===undefined) ||  (code===null) || findLanguage(code)===undefined) {
    return undefined;
  }

  let newClassName = (className!==undefined)?className:'inline w-5';

  switch (code) {
    case 'tr':
      return <Image src={tr} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'sk':
      return <Image src={sk} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'en':
      return <Image src={en} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'es':
      return <Image src={es} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'cs':
      return <Image src={cs} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'ru':
      return <Image src={ru} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'uk':
      return <Image src={uk} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'th':
      return <Image src={th} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'hi':
      return <Image src={hi} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'fr':
      return <Image src={fr} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'de':
      return <Image src={de} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'pt':
      return <Image src={pt} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'ja':
      return <Image src={ja} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'it':
      return <Image src={it} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'pl':
      return <Image src={pl} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'sv':
      return <Image src={sv} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'nl':
      return <Image src={nl} className={newClassName} alt={findLanguage(code)?.name_local||''} />
    case 'ar':
      return <Image src={ar} className={newClassName} alt={findLanguage(code)?.name_local||''} />
  }
  return undefined;
}