'use client'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'
import { Avatar } from '@nextui-org/avatar'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/dropdown'
import NextImage from 'next/image'
import { useRouter } from 'next/navigation'
import { useState } from 'react'
import ConfirmationModal from '../core/ConfirmationModal'
import chevronDown from './MenuLinks/assets/chevron_down.svg'

export default function ProfileCard({
  className = '',
  menuLinkOnClick = () => {},
}: {
  className?: string
  menuLinkOnClick?: () => void
}) {
  const supabase = getClientSupabase()
  const router = useRouter()
  const [confirmationModalShow, setConfirmationModalShow] =
    useState<boolean>(false)

  function signOut() {
    supabase.auth.signOut().then(() => {
      menuLinkOnClick()
      router.push('/login')
      router.refresh()
    })
  }

  function goToProfile() {
    menuLinkOnClick()
    router.push('/profile')
  }

  return (
    <>
      <Dropdown placement="bottom-end" className={className}>
        <DropdownTrigger>
          <div className={'flex flex-row cursor-pointer'}>
            <Avatar className={'inline-block shrink-0'} />

            <NextImage
              src={chevronDown}
              alt={'dropdown'}
              className={'h-7 w-7 ms-1 mt-1 self-start'}
            />
          </div>
        </DropdownTrigger>
        <DropdownMenu aria-label="Profile Actions" variant="flat">
          <DropdownItem
            onPress={() => setConfirmationModalShow(true)}
            key="logout"
            color="danger"
          >
            Log Out
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <ConfirmationModal
        isOpen={confirmationModalShow}
        event={signOut}
        onClose={() => setConfirmationModalShow(false)}
      >
        Do you want to log out?
      </ConfirmationModal>
    </>
  )
}
