'use client'
import { useSearchParams } from 'next/navigation'
import {
  Alert
} from '@nextui-org/react'
import React from "react";

export default function Messages() {
  const searchParams = useSearchParams()
  const [isVisible, setIsVisible] = React.useState(true);

  const error = searchParams.get('error')
  const message = searchParams.get('message')
  return (
    <>
      {(isVisible && (error || message)) && (
        <Alert
          hideIconWrapper
          color="danger"
          title={error || message}
          variant="faded"
          onClose={() => setIsVisible(false)}
        />
      )}
    </>
  )
}
