import platformLanguagesRaw from './platform-languages.json'

export type language = {
    code: string
    name_english: string
    name_local: string
    flag_emoji: string
    flag_unicode: string
    flag_image: string
    i18_code: string
    elia_code: string
    heygen_code: string
    azure_code_includes: string
    azure_code_default: string
    avatar_text: string
    parity_code: string
    parity_text: string
    switch_to_lang_text: string
    candidate_speak_text: string
    interactive_end_reason: string
    enable_avatar_templates: boolean
    enable_consumer_coaching: boolean
    enable_consumer_interface: boolean
    enable_interview_interface: boolean
    enable_admin_interface: boolean
  }

export type customLangInterview = {
  mojito_language_code: string
  hero_box: string | undefined
  about_box: string | undefined
  contact_us_page: string | undefined
  readmore_box_text: string | undefined
  readmore_box_link: string | undefined
  readmore_box_button: string | undefined
  support_page: string | undefined
  quote: string | undefined
  interview_completed_box: string | undefined
  discover_id: string | undefined
}

export type customLangWhitelabel = {
  mojito_language_code: string
  header_title: string | undefined
  header_box: string | undefined
  footer_box: string | undefined
  support_page: string | undefined
}

export const languagesRaw:language[] = platformLanguagesRaw;

export const languagesSorted :language[] = platformLanguagesRaw.sort((a, b) => {
    const fa = a.name_english.toLowerCase();
    const fb = b.name_english.toLowerCase();

    if (fa < fb) { return -1; }
    if (fa > fb) { return 1; }
    return 0;
  });

  export function findLanguage(code: string | null): language | undefined {
    return languagesRaw.find((element) => element.code === code);
  }