'use client'
import { useError } from '@jobmojito/components/providers/ErrorProvider'
import { Modal, ModalContent } from '@nextui-org/modal'
import { Alert } from '@nextui-org/react'

export default function ErrorModal() {
  const { error, type, clearError } = useError()

  return (
    <>
      <Modal
        backdrop={'transparent'}
        className={
          //`rounded-[5px] max-w-[400px] w-auto pe-5 ${(type==='success'?'text-success bg-success-50':'text-danger bg-default-800 border-default-800')}`
          `max-w-[400px] w-auto`
        }
        classNames={{ wrapper: 'sm:items-start' }}
        isOpen={!!error}
        onClose={clearError}
        placement={'top-center'}
      >
        <ModalContent>
          <Alert
            hideIconWrapper
            color={type==='success'?"success":(type==='warning'?'warning':(type==='default'?'default':"danger"))}
            description={(error===null)?undefined:error}
          />

        </ModalContent>
      </Modal>
    </>
  )
}
