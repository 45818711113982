import BlockTitle from '@jobmojito/admin-app/components/core/BlockTitle'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from '@nextui-org/modal'
import React from 'react'
import { Button } from '@nextui-org/button'

export default function ConfirmationModal({
  isOpen,
  event,
  onClose,
  children,
  confirmTextButton = 'Yes',
  refusTextButton = 'No',
}: {
  isOpen: boolean
  event: () => void
  onClose: () => void
  children: React.ReactNode
  confirmTextButton?: string
  refusTextButton?: string
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      size={'xl'}
      placement={'center'}
      scrollBehavior={'inside'}
    >
      <ModalContent className={'mt-10'}>
        <ModalHeader>
          <BlockTitle size="sm" title={'Warning'} />
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button variant="ghost" onPress={() => onClose()}>
            {refusTextButton}
          </Button>
          <Button
            onPress={async () => typeof event === 'function' && (await event())}
          >
            {confirmTextButton}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
