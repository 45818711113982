import { createBrowserClient } from '@supabase/ssr'
import { Database } from '@jobmojito/config/types/supabase'
import clientGetCookies from '@jobmojito/components/core/clientCookies'

export default function getClientSupabase() {
  const token = clientGetCookies('interview_token')

  const options = {
    global: {
      headers: { Authorization: 'Bearer ' + token },
    },
  }
  const supabase = token
    ? createBrowserClient<Database>(
        process.env.NEXT_PUBLIC_SUPABASE_URL!,
        process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
        options,
      )
    : createBrowserClient<Database>(
        process.env.NEXT_PUBLIC_SUPABASE_URL!,
        process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
      )

  if (token) supabase.realtime.setAuth(token)
  /*
  const supabase = (token?
      createClient(
        process.env.NEXT_PUBLIC_SUPABASE_URL!,
        process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
        options
      )
      :createBrowserClient<Database>(
        process.env.NEXT_PUBLIC_SUPABASE_URL!,
        process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
        ((token!==undefined) ? { cookieOptions: { global: { headers: { 'Authorization': 'bearer '+ token } } } }:undefined)
      ));
  */
  return supabase
}
