'use client'
import LandingMenuLink from '@jobmojito/admin-app/(landing)/landing/components/LandingMenuLink'
import { Button, Link } from '@nextui-org/react'
import { NavbarContent, Navbar as NextUiNavbar } from '@nextui-org/navbar'
import React from 'react'
import { useTranslations } from 'next-intl'
import LanguageSelector from '@jobmojito/components/core/LanguageSelector'
import {
  findLanguage,
  language,
  languagesSorted,
} from '@jobmojito/admin-app/utils/data/platform-languages'

export default function Navbar({
  merchantLogo,
  merchantLink,
  isFooter = false,
}: {
  merchantLogo: React.JSX.Element
  merchantLink?: React.JSX.Element
  isFooter?: boolean
}) {
  const t = useTranslations('component-admin-landing')
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <NextUiNavbar
      maxWidth={'xl'}
      isBordered={!isFooter}
      className={'text-base bg-white fixed'}
      onMenuOpenChange={setIsMenuOpen}
      isMenuOpen={isMenuOpen}
    >
      <div className={'w-40 md:w-44 h-full'}>
        <div className="relative w-full h-full flex flex-row items-center">
          {merchantLogo}
        </div>
      </div>
      <NavbarContent justify={'end'}>
        {merchantLink && merchantLink}
        <LanguageSelector
          isLarge
            languages={languagesSorted.filter((l) =>
              l.enable_admin_interface===true
            )}
          />
        <Button as={Link} href='/login' variant='ghost' size='md' color='primary'>{t('Login & Sign up')}</Button>
      </NavbarContent>
    </NextUiNavbar>
  )
}
